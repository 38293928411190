/*.scroller {
    background-image: url('../img/groovepaper.png')!important;
    background-repeat: repeat;
}*/
.bb-custom-wrapper nav span, .menu-button {
    background: $brown;
}
.menu-panel {
    background: $brown;
}
.menu-toc li a {
    background: $brown;
    border-bottom: 1px solid darken($brown, 10%);
}
.menu-toc li a:hover, .menu-toc li.menu-toc-current a {
    background: darken($brown, 10%);
}
.menu-panel div a {
    color: lighten($brown, 10%);
}
.menu-panel {
    overflow-y: auto;
}

.menu-panel div a {
    font-size: 16px;
    color: #fff;
}

.content p.text {
        &-left {
            text-align: left;
        }
        &-right {
            text-align: right;
        }
        &-center {
            text-align: center;
        }
        &-justify {
            text-align: justify;
        }
}
.content h1 {
    margin: 0 1% 40px;
    padding: 0 0 10px;
    font-size: 2.8em;
    font-weight: 300;
    color: #333;
    text-align: left;
    box-shadow: 0 10px 0 rgba(0,0,0, 0.02);
    text-shadow: 0 0 2px #fff;
    @media screen and (max-width: 575px) {
        font-size: 2em;
    } 
}
.content h2 {
    font-size: 2.5em;
}

.zmist {
    &-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 992px) {
            justify-content: flex-end;
            width: 100%;
        }
    }
}

.btn {
    cursor: pointer;
    padding: 5px 15px;
    margin: 0 5px;
    @media screen and (max-width: 992px) {
        margin: 5px 5px 15px;
        display: block;
        max-width: 120px;
        text-align: center;
    }
    &--brown {
        border: 1px solid $brown;
        font-weight: 300;
        font-size: 1em;
        &:hover, &:focus {
            box-shadow: 0 0 10px rgba($brown, 0.5);
            color: $white;
            background-color: $brown;
        }
    }
}
body, div, p, pre {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

button {
    cursor: pointer;
    &.modal-btn {
        border: none;
        border-bottom: 1px dotted $black;
        background: transparent;
        font-size: 1em;
        font-weight: 200;
        font-family: $font;
        text-decoration: none;
        &:hover {
            outline: none;
            color: $brown;
            border-color: $brown;
        }
        &:focus {
            outline: none;
        }
    }
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.4);
    z-index: 1001;
    &-window {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: 50px auto;
        background-color: $white;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 6px rgba($black, 0.6);
        .close {
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
            height: 30px;

            &-btn {
                font-size: 32px;
                font-weight: 300;
                font-family: $font;
                border: none;
                border-radius: 50%;
                background-color: transparent;
                &:focus {
                    outline: none;
                }
            }
        }
        p {
            font-size: 1.2em;
            font-weight: 300;
            font-family: $font;
            line-height: 1.6;
            @media screen and (max-width: 991px) {
                font-size: 1.2em;
            }
        }
        @media screen and (max-width: 575px) {
            width: 94%;
        }
        @media screen and (max-width: 767px) {
            width: 86%;
        }
        @media screen and (max-width: 991px) {
            width: 70%;
        }
        @media screen and (min-width: 992px) {
            width: 100%;
            max-width: 620px;
            padding: 30px;
        }
    }
}

.riderict {
    &-container {
        display: block;
        margin: 100px auto;
        box-shadow: 0 0 6px rgba($black, 0.4);
        background-color: $brown;
        padding: 30px;
        border-radius: 5px;
        @media screen and (max-width: 575px) {
            width: 90%;
            padding: 15px;
        }
        @media screen and (min-width: 576px) {
            width: 84%;
        }
        @media screen and (min-width: 992px) {
            width: 100%;
            max-width: 1000px;
        }
    }
    &-lnk {
        color: $white;
        text-transform: none;
        text-decoration: underline;
        font-size: 1.5em;
        color: $white;
        font-family: $font;
        font-weight: 300;
        @media screen and (max-width: 575px) {
            font-size: 1.2em;
        }
    }
    h1 {
        font-family: $font;
        font-size: 2.4em;
        color: $white;
        font-weight: 600;
        @media screen and (max-width: 575px) {
            font-size: 1.6em;
        }
    }
    h2 {
        font-family: $font;
        font-size: 2em;
        font-weight: 300;
        color: $white;
        @media screen and (max-width: 575px) {
            font-size: 1.2em;
        }
    }
}

.virsh {
    text-align: left;
    max-width: 400px;
    width: auto;
    margin: 0 auto;
}

pre.virsh {
    font-size: 1.2em;
    font-family: $font;
    color: $black;
    font-weight: 300;
    line-height: 1.6;
}